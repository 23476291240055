.App {
    text-align: center;
    font-family: Monaco, monospace;
    color: #FFF7EE;
}



.homepage-logo {
    margin-bottom: 30px;
    margin-top: 40px;
    max-width: 80vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 40vw;
}

#footer {
    position: fixed;
    text-align: center;
    font-family: Monaco, monospace;
    bottom: 0;
    left: 0;
    color: #524438;
    right: 0;
    padding-bottom: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

/*.feed-link {*/
/*  margin-top: 2vw;*/
/*  font-size: 3vw;*/
/*  text-decoration: none;*/
/*  color: black;*/
/*}*/

/*.feed-link:visited {*/
/*  color: black;*/
/*}*/

.button {
    position: relative;
    display: inline-block;
    margin: 20px;
}

.button a {
    color: #FFF7EE;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    text-decoration: none;
    background-color: #FFA12B;
    display: block;
    position: relative;
    padding: 20px 40px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


    -webkit-box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.button a:active {
    top: 10px;
    background-color: #F78900;

    -webkit-box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3 pxpx 0 #915100;
    box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
}

.button:after {
    content: "";
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: #2B1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
