.twitch-container{
    display: flex;
    align-items: flex-start;
    margin-top: 1vw;
}

.stream-container{
    margin-left: 1vw;
    width: 72vw;
    height: 75vh;
}

.chat-container{
    margin-left: 1vw;
    height: 74.5vh;
    width: 24vw;
}

.input-container {
    margin-top: 2vh;
    margin-left: 1vw;
    display: flex;
    align-items: flex-start;
}

.joypad-container {
    margin-left: 5vw;
    display:flex;
    flex-direction: column;
    align-items: center;
}


div.button {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    cursor:pointer;
    z-index: 99;
}

div.button::selection {
    cursor:pointer;

}

body {
    background-color: #009D84;
    font-family: monospace;
}

.button {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.button a {
    color: #FFF7EE;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    text-decoration: none;
    background-color: #FFA12B;
    display: block;
    position: relative;
    padding: 10px 20px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    -webkit-box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.button a:active {
    top: 10px;
    background-color: #F78900;

    -webkit-box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
    -moz-box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
    box-shadow: inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
}

.button:after {
    content: "";
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: #2B1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

#queueStatusContainer {
    position: absolute;
    text-align: center;
    color: #00A245;
    background-color: #2b2a2b;
    width: 50%;
    left: 25%;
    padding: 5px;
    border-radius: 10px;
}

#queueStatus {
    margin: auto;
    left: 50%;
}

#quack {
    width: 40vw;
    margin: auto;
}

#quackText {
    margin-bottom: 40px;
}

#head {
    position: fixed;
    bottom: 0;
    right: 1vw;
    max-width: 10vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    cursor:pointer;
    z-index: 99;

}


